<!-- 能耗管理--自动报表 -->
<template>
  <div id="automaticReport" v-loading="loading" :element-loading-text="$t('alarm.a0')">
    <div class="page-title">
        <my-PageTitle>{{ $t('automaticReport.a1') }}</my-PageTitle>
    </div>
    <div class="main">
      <div class="sider">
        <div class="sider-header">
          <span>{{ $t('automaticReport.a2') }}</span>
          <a-button type="primary" ghost icon="plus" size="small" @click="openStatementAdd">
            {{ $t('automaticReport.a3') }}
          </a-button>
        </div>
        <div class="menu">
          <!-- 侧边栏 -->
          <a-menu mode="inline" v-model="menuSelect">
            <a-sub-menu v-for="(item,index) in automaticReportGrList" :key="index">
              <span slot="title">
                {{ item.automaticReportGrName }}
              </span>
                <a-menu-item v-for="(items,index) in item.automaticReportList" :key="items.id" @click="getAutomaticReportData(item,items)">
                  <div class="menu-item">
                    <div>
                      {{ `${pad(index + 1,2)}、${items.name}` }}
                    </div>
                    <div>
                      <a-icon type="form" :style="{ fontSize: '18px',color: '#7682CE' }" @click.stop="openNameChange(items,item)" />
                      <a-icon type="delete" :style="{ fontSize: '18px',color: '#7682CE' }" @click.stop="deleteStatement(items,item)" /> 
                    </div>
                  </div>
                </a-menu-item>
              </a-sub-menu>
          </a-menu>
        </div>
      </div>
      <div class="empty" v-show="automaticReportSelect.automaticReportGrId == undefined">
        <a-empty>
          <span class="explain">
              {{ $t('automaticReport.a4') }}
          </span>       
         </a-empty>
      </div>
      <div class="item" v-show="automaticReportSelect.automaticReportGrId !== undefined">
        <div class="item-header">
          <div>
            <div class="condition">
              <!-- <span class="name">起始时间</span>
              <a-time-picker :default-value="moment('12:08', 'HH:mm')" format="HH:mm" style="margin-right:15px" /> -->
              <span class="name">{{ $t('automaticReport.a5') }}</span>
              <a-time-picker 
                v-if="automaticReportSelect.automaticReportGrType == 201" 
                v-model="automaticReportScheduleVO.executeDateTime" 
                :allowClear="false" 
                format="HH:mm" 
                style="margin-right:15px;width:100px" 
              />
              <a-date-picker 
                v-if="automaticReportSelect.automaticReportGrType == 202" 
                v-model="automaticReportScheduleVO.executeDateTime" 
                :allowClear="false" 
                show-time 
                format="DD HH:mm" 
                style="margin-right:15px;min-width:100px;width:100px"
                @panelChange="panelChange"
              />
              <a-date-picker 
                v-if="automaticReportSelect.automaticReportGrType == 203" 
                v-model="automaticReportScheduleVO.executeDateTime" 
                :allowClear="false" 
                show-time 
                format="MM-DD HH:mm" 
                style="margin-right:15px;min-width:100px;width:100px"
              />
              <span class="name">{{ $t('automaticReport.a6') }}</span>
              <a-select v-model="templateSelect" style="width: 120px;margin-right:15px">
                <a-select-option v-for="(item,index) in templateList" :key="index" :value="item.id">
                  {{ item.name }}
                </a-select-option>
              </a-select>
              <a-popover :getPopupContainer="triggerNode => { return triggerNode.parentNode }" placement="bottomLeft">
                <template slot="content">
                    <a-table 
                      :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }" 
                      :columns="signalLocationColumns" 
                      :data-source="signalLocationData" 
                      :pagination="false"
                      size="middle"
                      :scroll="scroll"
                      :rowKey="(record,index) => index"
                    >
                    </a-table>
                </template>
                <a-button type="primary" ghost style="margin-right:15px">
                  {{ $t('automaticReport.a7') }}
                <a-icon type="down" :style="{ fontSize: '12px' }"/>
                </a-button>
              </a-popover>
              <a-checkbox v-model="automaticReportScheduleVO.enabled">
                {{ $t('automaticReport.a8') }}
              </a-checkbox>
            </div>
          </div>
          <div>
            <a-button type="primary" @click="statementSet">
              {{ $t('automaticReport.a9') }}
            </a-button>
          </div>
        </div>
        <div class="listed-files" ref="main">
          <!-- 表格 -->
          <a-table :columns="fileColumns" :data-source="fileData" :scroll="size" :rowKey="(record) => record.url">
            <template slot="operation" slot-scope="text,record">
              <a-button type="primary" @click="fileDownload(record)" :loading="record.loading" :disabled="record.disabled">
                {{ $t('automaticReport.a21') }}
              </a-button>
            </template>
          </a-table>
        </div>
        <div class="item-floor">
          {{ $t('automaticReport.a10') }}
        </div>
      </div>
    </div>
    <!-- 添加报表 -->
    <a-modal v-model="statementAddVisible" :title="$t('automaticReport.a13')" centered :width="300" @cancel="statementAddCancel" :destroyOnClose="true" v-drag>
        <template slot="footer">
            <a-button key="back" @click="statementAddCancel">
                {{ $t('automaticReport.a11') }}
            </a-button>
            <a-button key="submit" type="primary" @click="statementAddOK" :disabled="disabled">
                {{ $t('automaticReport.a12') }}
            </a-button>
        </template>
        <div v-loading="statementAddLoading" :element-loading-text="$t('alarm.a0')">
            <div class="padding">
                <a-form-model ref="ruleForm" :model="addData" :rules="rules">
                    <a-form-model-item :label=" $t('automaticReport.a14')" prop="name">
                        <a-input v-model="addData.name" :maxLength="30" :placeholder=" $t('automaticReport.a14')" allow-clear style="width: 200px"/>
                    </a-form-model-item>
                    <a-form-model-item :label="$t('automaticReport.a15')">
                        <a-select v-model="statementTypeSelect" style="width: 200px">
                          <a-select-option v-for="(item,index) in automaticReportGrList" :key="index" :value="item.automaticReportGrType">
                            {{ item.automaticReportGrName }}
                          </a-select-option>
                      </a-select>
                    </a-form-model-item>
                </a-form-model>
            </div>
        </div>
    </a-modal>
    <!-- 报表名称修改 -->
    <a-modal v-model="nameChangeVisible" :title="$t('automaticReport.a17')" centered :width="300" @cancel="nameChangeCancel" :destroyOnClose="true" v-drag>
        <template slot="footer">
            <a-button key="back" @click="nameChangeCancel">
                {{ $t('automaticReport.a11') }}
            </a-button>
            <a-button key="submit" type="primary" @click="nameChangeOK" :disabled="disabled">
                {{ $t('automaticReport.a12') }}
            </a-button>
        </template>
        <div v-loading="nameChangeLoading" :element-loading-text="$t('alarm.a0')">
            <div class="padding">
                <a-form-model ref="Form" :model="nameChangeData" :rules="rules">
                    <a-form-model-item :label="$t('automaticReport.a14')" prop="name">
                        <a-input v-model="nameChangeData.name" :maxLength="30" :placeholder="$t('automaticReport.a16')" allow-clear style="width: 200px"/>
                    </a-form-model-item>
                </a-form-model>
            </div>
        </div>
    </a-modal>
  </div>
</template>

<script>
import PageTitle from '../../components/Title/PageTitle.vue'
import { 
  getAutomaticReportGrList,
  automaticReportAdd,
  automaticReportdelete,
  automaticReportNameUpdate,
  getAutomaticReportGrDetail,
  getAutomaticReportFiles,
  setAutomaticReport,
  automaticReportDownload} from '../../api/energy'
import moment from 'moment'
import { EleResize } from '../../utils/esresize'

export default {
    components:{
        'my-PageTitle':PageTitle
    },
    data(){
      return{
        loading:false,
        automaticReportGrList:[],
        automaticReportSelect:{},
        automaticReportListSelect:{},
        statementAddVisible:false,
        disabled:false,
        downloadDisabled:false,
        statementAddLoading:false,
        addData:{
          name:'',
        },
        nameChangeData:{
          name:''
        },
        nameChangeDataCopey:{},
        nameChangeDatas:{},
        rules: {
            name: [
                { required: true, message: this.$t('automaticReport.a16'), trigger: 'change' },
            ],
        },
        statementTypeSelect:201,
        nameChangeVisible:false,
        nameChangeLoading:false, 
        visible:false,
        menuSelect:[],
        automaticReportScheduleVO:{
          enabled:false,
          executeDateTime:moment(),
          id:0,
          startDateTime:''
        },
        templateList:[],
        templateSelect:0,
        unSelectedPointList:[],
        signalLocationData:[],
        selectedRowKeys: [],
        signalLocationColumns:[
          {
            title: this.$t('automaticReport.a18'),
            dataIndex: 'pointName'
          }
        ],
        fileColumns:[
          {
            title:'NO.',
            dataIndex: "NO",
            customRender: (text, record, index) => `${ index + 1 }`,
            width:60
          },
          {
            title: this.$t('automaticReport.a19'),
            dataIndex: 'fileName'                                                                                                                        
          },                             
          {
            title: this.$t('automaticReport.a22'),
            dataIndex: 'operation',
            scopedSlots: { customRender: 'operation' },
          }
        ],
        fileData:[],
        setButDisabled:false,
        scroll:{ x: 250, y: 300 },
        size:{ y: 300 }
      }
    },
    // 页面初始化获取侧边栏数据
    mounted(){
      this.loading = true
      const data = { siteId:this.$route.query.id }
      getAutomaticReportGrList(data)
      .then(res=>{
        console.log(res)
        const { data } = res
        this.automaticReportGrList = data
        this.loading = false
      })
      .catch(err=>{
        console.log(err)
        this.loading = false
      })
      this.tableSize();
      window.addEventListener("resize", this.tableSize)
    },
    beforeDestroy() {
      window.removeEventListener("resize", this.tableSize)
    },
    methods:{
      moment,
      // 表格自适应方法
      tableSize() {
        let height = this.$refs.main.clientHeight
        let tableHeight = height - 100
        console.log(tableHeight)
        this.size.y = tableHeight
      },
      // 数字格式化API
      pad(n, length) {
        var len = length - (''+n).length;
        return (len > 0 ? new Array(++len).join('0') : '') + n
      },
      // 打开新增报表弹框
      openStatementAdd(){
        this.statementAddVisible = true
      },
      // 关闭新增报表弹框
      statementAddCancel(){
        this.statementAddVisible = false
        this.addData.name = ''
      },
      // 添加报表API
      statementAddOK(){
        this.$refs.ruleForm.validate(valid => {
                if (valid) {
                  this.statementAddLoading = true
                  this.disabled = true
                  const list = this.automaticReportGrList
                  const type = this.statementTypeSelect
                  let data = {}
                  for(let i = 0;i < list.length;i++){
                    if(list[i].automaticReportGrType == type){
                      const _obj = JSON.stringify(list[i])
                      const obj = JSON.parse(_obj)
                      delete obj.automaticReportList
                      data = obj
                      data.automaticReportList = [{ name:this.addData.name}]
                    }
                  }
                  automaticReportAdd(data)
                  .then(res=>{
                    // console.log(res)
                    if(res.errorCode == '00'){
                        const { data } = res
                        for(let i = 0;i < list.length;i++){
                          if(list[i].automaticReportGrType == data.automaticReportGrType){
                            this.automaticReportGrList[i].automaticReportList.push(data.automaticReportList[0])
                          }
                        }
                        this.$message.success(res.msg)
                        this.statementAddCancel()
                    }else{
                        this.$message.error(res.msg)
                    }
                    this.statementAddLoading = false
                    this.disabled = false
                  })
                  .catch(err=>{
                    console.log(err)
                    this.statementAddLoading = false
                    this.disabled = false
                  })
                } else {
                    return false
                }
            })
      },
      // 打开名称修改弹框
      openNameChange(items,item){
        this.nameChangeDataCopey = items
        const _data = JSON.stringify(items)
        const data = JSON.parse(_data)
        this.nameChangeData = data
        const _datas = JSON.stringify(item)
        const datas = JSON.parse(_datas)
        this.nameChangeDatas = datas
        this.nameChangeData
        this.nameChangeVisible = true 
      },
      // 关闭名称修改弹框
      nameChangeCancel(){
        this.nameChangeVisible = false
      },
      // 报表名称修改API
      nameChangeOK(){
        this.$refs.Form.validate(valid => {
                if (valid) {
                  this.nameChangeLoading = true
                  this.disabled = true
                  const nameChangeDatas = this.nameChangeDatas
                  const nameChangeData = this.nameChangeData
                  const data = {
                    automaticReportGrId:nameChangeDatas.automaticReportGrId,
                    automaticReportGrName:nameChangeDatas.automaticReportGrName,
                    automaticReportGrType:nameChangeDatas.automaticReportGrType,
                    automaticReportList:[nameChangeData]
                  }
                  automaticReportNameUpdate(data)
                  .then(res=>{
                    // console.log(res)
                    if(res.errorCode == '00'){
                      this.nameChangeDataCopey.name = res.data.automaticReportList[0].name
                      this.nameChangeCancel()
                      this.$message.success(res.msg)
                    }else{
                      this.$message.error(res.msg)
                    }
                    this.nameChangeLoading = false
                    this.disabled = false
                  })
                  .catch(err=>{
                    console.log(err)
                    this.nameChangeLoading = false
                    this.disabled = false
                  })
                } else {
                    return false
                }
            })
      },
      // 删除报表列表
      deleteStatement(items,item){
        this.$confirm({
                title: `${ this.$t('automaticReport.a20')}${items.name}?`,
                centered: true,
                onOk:()=> {
                    return new Promise((resolve, reject) => {
                      const _data = JSON.stringify(item)
                      const data = JSON.parse(_data)
                      data.automaticReportList = [items]
                      automaticReportdelete(data)
                      .then(res=>{
                        // console.log(res)
                        if(res.errorCode == '00'){
                          this.$message.success(res.msg)
                          const list = item.automaticReportList
                          item.automaticReportList = list.filter(item=> item.id !== items.id)
                        }else{
                          this.$message.error(res.msg)
                        }
                        resolve()
                      })
                      .catch(err=>{
                        console.log(err)
                        reject()
                      })
                    }).catch(() => { return false})
                },
            })
      },
      // 获取报表详情数据、获取报表文件列表
      async getAutomaticReportData(item,items){
        console.log(item,items)
        this.loading = true
        this.automaticReportSelect = item
        this.automaticReportListSelect = items
        const data = { 
          automaticReportGrType:item.automaticReportGrType,
          id:items.id
        }
        await getAutomaticReportGrDetail(data)
        .then(res=>{
          console.log(res)
          const { data:{ automaticReportScheduleVO,templateList,unSelectedPointList,selectedPointList,template }} = res
          if(automaticReportScheduleVO){
            this.automaticReportScheduleVO.enabled = automaticReportScheduleVO.enabled == 0 ? false : true
            this.automaticReportScheduleVO.id = automaticReportScheduleVO.id
            this.templateSelect = template.id
            this.templateList = templateList
            if(selectedPointList){
              this.signalLocationData = [...selectedPointList,...unSelectedPointList]
              this.selectedRowKeys = selectedPointList.map((item,index) =>{ return index })
            }else{
              this.signalLocationData = unSelectedPointList
            }
            if(item.automaticReportGrType == 201){
              this.automaticReportScheduleVO.executeDateTime = moment(automaticReportScheduleVO.executeDateTime,'HH:mm')
            }else if(item.automaticReportGrType == 202){
              this.automaticReportScheduleVO.executeDateTime = moment(automaticReportScheduleVO.executeDateTime,'DD HH:mm')
            }else if(item.automaticReportGrType == 203){
              this.automaticReportScheduleVO.executeDateTime = moment(automaticReportScheduleVO.executeDateTime,'MM-DD HH:mm')
            }
          }else{
            this.automaticReportScheduleVO.enabled = true
            this.signalLocationData = unSelectedPointList
            this.templateList = templateList
            this.templateSelect = templateList[0].id
            this.selectedRowKeys = []
            if(item.automaticReportGrType == 201){
              this.automaticReportScheduleVO.executeDateTime = moment('12:00','HH:mm')
            }else if(item.automaticReportGrType == 202){
              this.automaticReportScheduleVO.executeDateTime = moment('1 12:00','DD HH:mm')
            }else if(item.automaticReportGrType == 203){
              this.automaticReportScheduleVO.executeDateTime = moment('1-1 12:00','MM-DD HH:mm')
            }
          }
        })
        .catch(err=>{
          console.log(err)
        })
        const filesData = {
          automaticReportGrType:item.automaticReportGrType,
          id:items.id,
          siteId:this.$route.query.id
        }
        await getAutomaticReportFiles(filesData)
        .then(res=>{
          console.log(res)
          const { data } = res
          this.fileData = data.map(item=>{
            item.loading = false
            item.disabled = false
            return item
          })
          console.log(data)
        })
        .catch(err=>{
          console.log(err)
        })
        this.loading = false
      },
      // 信号点列表选择数组
      onSelectChange(selectedRowKeys) {
        // console.log(selectedRowKeys)
        this.selectedRowKeys = selectedRowKeys;
      },
      // 自动报表设置页面设置API
      statementSet(){
        this.loading = true
        const automaticReportGrType = this.automaticReportSelect.automaticReportGrType
        if(!this.automaticReportScheduleVO.executeDateTime){
          if(automaticReportGrType == 201){
            this.automaticReportScheduleVO.executeDateTime = moment('12:00','HH:mm')
          }else if(automaticReportGrType == 202){
            this.automaticReportScheduleVO.executeDateTime = moment('1 12:00','DD HH:mm')
          }else if(automaticReportGrType == 203){
            this.automaticReportScheduleVO.executeDateTime = moment('1-1 12:00','MM-DD HH:mm')
          }
        }
        const selectedRowKeys = this.selectedRowKeys
        const signalLocationData = this.signalLocationData
        const templateList = this.templateList
        const templateSelect = this.templateSelect
        const id = this.automaticReportListSelect.id
        const enabled = this.automaticReportScheduleVO.enabled ? 1 : 0
        let executeDateTime = ''
        const VOid = this.automaticReportScheduleVO.id
        if(automaticReportGrType == 201){
            executeDateTime = this.automaticReportScheduleVO.executeDateTime.format('HH:mm')
          }else if(automaticReportGrType == 202){
            executeDateTime = this.automaticReportScheduleVO.executeDateTime.format('DD HH:mm')
          }else if(automaticReportGrType == 203){
            executeDateTime = this.automaticReportScheduleVO.executeDateTime.format('MM-DD HH:mm')
          }
        const query = { 
          automaticReportGrType,
          id
        }
        const body = {
          automaticReportScheduleVO:{
            enabled,
            executeDateTime,
            id:VOid,
            startDateTime:0             
          },
          selectedPointList:signalLocationData.filter((item,index)=> selectedRowKeys.indexOf(index) > -1),
          template:templateList.filter(item => item.id == templateSelect)[0],
          unSelectedPointList:signalLocationData.filter((item,index)=> selectedRowKeys.indexOf(index) == -1),
          templateList:templateList
        }
        setAutomaticReport(query,body)
        .then(res=>{
          console.log(res)
          this.loading = false
          if(res.errorCode == '00'){
              this.$message.success(res.msg)
            }else{
              this.$message.error(res.msg)
            }
        })
        .catch(err=>{
          console.log(err)
          this.loading = false
        })
      },
      // 文件下载                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                   
      fileDownload(item){
        // console.log(item)
        item.loading = true
        item.disabled = true
        automaticReportDownload(item.url)
        .then(res=>{
          // console.log(res)
          const { data } = res
          let url = window.URL.createObjectURL(data)
          let link = document.createElement("a")
          link.style.display = "none"
          link.href = url
          link.setAttribute("download", item.fileName)
          document.body.appendChild(link)
          link.click()
          item.loading = false
          item.disabled = false
        })
        .catch(err=>{
          console.log(err)
          item.loading = false
        })
      },
      panelChange(value, mode){
        console.log(value, mode)
      }
    }
}
</script>

<style scoped>
#automaticReport{
    width: 100%;
    height: 100%;
}
.main{
  display: flex;
  height: calc(100% - 50px);
  padding: 10px 20px 0px 20px;
}
.sider{
  width: 300px;
  height: 100%;
  margin-right: 15px;
}
.menu{
  height: calc(100% - 32px);
  overflow: auto;
}
.item{
  width: calc(100% - 315px);
}
.sider-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  height: 32px;
}
.menu-item{
  display: flex;
  justify-content: space-between;
}
.item-header{
  display: flex;
  justify-content: space-between;
  padding-bottom: 15px;
}
.condition{
  display: flex;
  align-items: center;
}
.name{
  margin-right: 5px;
  font-size: 14px;
  color:rgba(0, 0, 0, 0.65);
}
.listed-files{
  height: calc(100% - 78px);
}
.item-floor{
  font-size: 14px;
  padding-bottom: 10px;
}
div /deep/.ant-modal-body{
  padding: 0px;
}
div /deep/.ant-modal-header{
  background-color: #7682CE;
}
div /deep/.ant-popover-inner-content{
  padding: 0px;
}
.padding{
  padding: 10px 20px 0px 20px;
}
.empty{
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% - 315px);
  height: 100%;
}
.explain{
    font-size: 20px;
    color: #7682ce;
}
</style>